<template>
    <form action="/" class="msearchForm">
        <input type="text" class="msearchForm__input"
               v-model="query"
        >
        <div class="loader" v-if="loading">
            <span class="loader__indicator"></span>
        </div>
        <button class="msearchForm__submit"
                @click.prevent="submit"
        >
            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.9545 15.9545L22 22M18.1818 9.59091C18.1818 14.3355 14.3355 18.1818 9.59091 18.1818C4.84628 18.1818 1 14.3355 1 9.59091C1 4.84628 4.84628 1 9.59091 1C14.3355 1 18.1818 4.84628 18.1818 9.59091Z"
                      stroke="#200A0F" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </button>

        <div class="msearchForm__results" v-if="results.length > 0">
            <p class="msearchForm__header">
                <span class="msearchForm__query">{{ meta.query }}</span>
                <span class="msearchForm__count">{{ meta.count }}</span>
            </p>

            <button class="msearchForm__clear"
                    @click.prevent="clear"
            >
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17 1H3C1.89543 1 1 1.89543 1 3V17C1 18.1046 1.89543 19 3 19H17C18.1046 19 19 18.1046 19 17V3C19 1.89543 18.1046 1 17 1Z" stroke="#66C3D0" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6 6L14 14" stroke="#200A0F" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M14 6L6 14" stroke="#200A0F" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

            </button>


            <div class="msearchForm__list">
                <a href="#" class="msearchForm__item" v-for="item in results"
                   :key="item.pagetitle"
                >
                    <span class="msearchForm__left">
                        <img :src="item.image" alt="" class="msearchForm__image">

                        <span class="msearchForm__content">
                            <span class="msearchForm__title">{{ item.pagetitle }}</span>
                            <span class="msearchForm__description">{{ item.description }}</span>
                        </span>
                    </span>
                    <span class="msearchForm__right">
                        <span class="msearchForm__size">{{ item.size }}.</span>
                        <span class="msearchForm__price">{{ item.price }} тнг</span>
                    </span>
                </a>
            </div>
            <a :href="meta.link" class="msearchForm__all">Все результаты поиска</a>
        </div>
    </form>
</template>

<script>
import request from '@/request'

export default {
  name: 'vue-search',
  watch: {
    query () {
      this.results = []
      this.meta = {
        query: '',
        count: 0,
        link: ''
      }
      if (this.query.length > 2) {
        this.search()
      }
    }
  },
  data () {
    return {
      query: '',
      results: [],
      loading: false,
      meta: {
        query: '',
        count: 0,
        link: ''
      }
    }
  },
  methods: {
    async search () {
      this.loading = true
      const formData = new FormData()
      formData.append('query', this.query)
      const response = await request.get('search', formData)
      if (response.data.success === true) {
        this.loading = false
        this.results = response.data.items
        this.meta = response.data.meta
      }
    },
    clear () {
      this.query = ''
      this.results = []
      this.meta = {
        query: '',
        count: 0,
        link: ''
      }
    },
    submit () {
      if (this.meta.link !== '') {
        window.location.href = this.meta.link
      }
    },
  }
}
</script>