import Vue from 'vue'
import OrderApp from '@/components/Order/OrderApp.vue'
import CartApp from '@/components/Order/CartApp.vue'
import Search from '@/components/common/search'
import ModalAuth from '@/components/modalAuth/ModalAuth.vue'

import 'vue-tel-input/dist/vue-tel-input.css';

Vue.config.productionTip = false

const searchBlocks = document.querySelectorAll('.vue-search')
if (searchBlocks.length > 0) {
  searchBlocks.forEach($vueSearch => {
    if ($vueSearch) {
      new Vue({
        el: '#' + $vueSearch.id,
        data () {
          return {}
        },
        template: '<Search/>',
        components: {
          Search
        }
      })
    }
  })
}


const $vueOrder = document.querySelector('#vue-order')
if ($vueOrder) {
  const orderId = $vueOrder.dataset['orderId']
  const lang = $vueOrder.dataset['lang']
  new Vue({
    el: '#vue-order',
    data () {
      return {
        orderId,
        lang
      }
    },
    template: '<OrderApp/>',
    components: {
      OrderApp
    }
  })
}


const $vueCart = document.querySelector('#vue-cart')
if ($vueCart) {
  const orderId = $vueCart.dataset['orderId']
  const lang = $vueOrder.dataset['lang']
   new Vue({
    el: '#vue-cart',
    data () {
      return {
        orderId,
        lang
      }
    },
    template: '<CartApp/>',
    components: {
      CartApp
    },
  })
}


const $vueAuth = document.querySelector('#vue-auth')
if ($vueAuth) {
  const lang = $vueAuth.dataset['lang']
   new Vue({
    el: '#vue-auth',
    data () {
      return {
        lang
      }
    },
    template: '<ModalAuth/>',
    components: {
      ModalAuth
    },
  })
}