<template>
  <div class="container">
    <form class="cartPage__orderForm order">
      <p class="order__header" v-if="lang==='ru'">Оформление заказа</p>
      <p class="order__header" v-if="lang==='kz'">Тапсырыс беру</p>
      <div class="order__top">
        <div class="order__column">
          <div class="order__section">
            <p class="order__title" v-if="lang==='ru'">Покупатель</p>
            <p class="order__title" v-if="lang==='kz'">Сатып алушы</p>

            <div class="form__group">
              <input type="text" class="form__input" :placeholder="lang==='ru'?'Имя':'Аты'"
                     v-model="receiver"
                     :class="{'form__input--invalid': invalidFields.includes('receiver')}"
                     @change="sendField('receiver', receiver)"
              >
            </div>

            <vue-tel-input
                :defaultCountry="'kz'"
                :mode="'international'"
                :validCharactersOnly="true"
                v-model="telInputValue"
                :dropdownOptions="telDropdownOptions"
                :inputOptions="telInputOptions"
                :styleClasses="telInputStyleClasses"
                @validate="telInputValidate"
                @input="telInputMask"
            />
          </div>

          <div class="order__section">
            <p class="order__title" v-if="lang==='ru'">Оплата</p>
            <p class="order__title" v-if="lang==='kz'">Төлем</p>
            <div class="form__group order__payments">
              <div class="form__checkbox">
                <input type="radio" name="payment" id="payment_1"
                       @change="sendField('payment', 3)"
                       :checked="payment === 3"
                >
                <label for="payment_1" v-if="lang==='ru'">Картой курьеру</label>
                <label for="payment_1" v-if="lang==='kz'">Курьерге карта бойынша</label>
              </div>
              <div class="form__checkbox">
                <input type="radio" name="payment" id="payment_2"
                       @change="sendField('payment', 1)"
                       :checked="payment === 1"
                >
                <label for="payment_2" v-if="lang==='ru'">Наличными курьеру</label>
                <label for="payment_2" v-if="lang==='kz'">Курьерге қолма-қол ақша</label>
              </div>
              <div class="form__checkbox">
                <input type="radio" name="payment" id="payment_7"
                       @change="sendField('payment', 7)"
                       :checked="payment === 7"
                >
                <label for="payment_7">Выставить счет на Kaspi</label>
              </div>
            </div>
          </div>
        </div>
        <div class="order__column">
          <p class="order__title" v-if="lang==='ru'">Адрес доставки</p>
          <p class="order__title" v-if="lang==='kz'">Жеткізу мекенжайы</p>

          <div class="form__group">
            <input type="text" class="form__input order__address-one" :placeholder="lang==='ru'?'Улица':'Көше'"
                   @keyup="streetAutoComplete"
                   v-model="street"
                   :class="{'form__input--invalid': invalidFields.includes('street')}"
            >
            <input type="text" class="form__input order__address-two" :placeholder="lang==='ru'?'Дом':'үй'"
                   v-model="building"
                   @change="sendField('building', building)"
                   :class="{'form__input--invalid': invalidFields.includes('building')}"
            >
          </div>

          <ul class="order__addressItems" v-if="addresses.length > 0">
            <li class="order__addressItem" v-for="item in addresses"
                :key="item.name"
                @click="setAddress(item)"
            >{{ item.name }}
            </li>
          </ul>

          <div class="form__group">
            <input type="text" v-model="room" class="form__input order__address-three" :placeholder="lang==='ru'?' Кв./Офис':'Пәтер/Офис'"
                   @change="sendField('room', room)"
            >
            <input type="text" v-model="entrance" class="form__input order__address-four" :placeholder="lang==='ru'?'Подъезд':'Кіру'"
                   @change="sendField('entrance', entrance)"
            >
            <input type="text" v-model="floor" class="form__input order__address-five" :placeholder="lang==='ru'?'Этаж':'Еден'"
                   @change="sendField('floor', floor)"
            >
          </div>

          <div class="form__group">
            <textarea v-model="comment" class="form__textarea order__textarea"
                      @change="sendField('comment', comment)"
                      :placeholder="lang==='ru'?'Комментарий к заказу':'Тапсырысқа түсініктеме беріңіз'"
            ></textarea>
          </div>


        </div>
        <div class="order__column">
          <p class="order__title" v-if="lang==='ru'">Дата/Время доставки</p>
          <p class="order__title" v-if="lang==='kz'">Жеткізу күні/уақыты</p>

          <div class="form__group">
            <div class="form__checkbox">
              <input type="checkbox" id="soon"
                     @change="setSoon"
                     :checked="soon === true"
              >
              <label for="soon"  v-if="lang==='ru'">Доставка сразу</label>
              <label for="soon"  v-if="lang==='kz'">Бірден жеткізу</label>
            </div>
          </div>

          <div class="form__group">
            <DatePicker
                v-model="date"
                :placeholder="lang==='ru'?'Выбрать дату':'Күнді таңдаңыз'"
                :disabled-date="disableDates"
                :type="'date'"
                :format="'DD.MM'"
                :editable="false"
                :clearable="false"
                @change="changeDate"
                :disabled="disabledDate"
            />
          </div>

          <div class="form__group">
            <date-picker
                v-model="time"
                value-type="format"
                type="time"
                :format="'HH:mm'"
                :placeholder="lang==='ru'?'Выбрать время':'Уақытты таңдаңыз'"
                :minute-step="30"
                :default-value="new Date().setHours(10, 0, 0, 0)"
                :disabled-time="disableNotWorkingTime"
                :time-picker-options="this.times"
                :disabled="disabledTime"
                @change="changeTime"
            />
          </div>
        </div>
      </div>
      <div class="order__bottom">
        <div class="order__total">
          <p class="order__totalItem">
            <span v-if="lang==='ru'">Сумма в корзине:</span>
            <span v-if="lang==='kz'">Себеттегі сома:</span>
            <span class="order__cartCost">{{ cartCost }} тнг</span>
          </p>
          <p class="order__totalItem">
            <span v-if="lang==='ru'">Доставка:</span>
            <span v-if="lang==='kz'">Жеткізу:</span>
            <span class="order__deliveryCost">{{ deliveryCost }} тнг</span>
          </p>
          <p class="order__totalItem">
            <span v-if="lang==='ru'">ИТОГО К ОПЛАТЕ:</span>
            <span v-if="lang==='kz'">БАРЛЫҒЫ ТӨЛЕУ:</span>
            <span class="order__cost">{{ totalCost }} тнг</span>
          </p>

          <button class="order__submit" type="button" @click="submit">
            <span v-if="lang==='ru'">Оформить заказ</span>
            <span v-if="lang==='kz'">Шығу</span>
          </button>

          <p class="order__offerta" v-if="lang==='ru'">Совершая покупку, Вы соглашаетесь с <a href="#">условиями покупки (оферта)</a></p>
          <p class="order__offerta" v-if="lang==='kz'">Сатып алу арқылы сіз сатып <a href="#">алу шарттарымен келісесіз (ұсыныс)</a></p>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { VueTelInput } from 'vue-tel-input'
import request from '@/request'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ru'

export default {
  name: 'Order-App',
  components: {
    VueTelInput,
    DatePicker
  },
  created () {
    this.orderId = this.$parent.orderId
    this.lang = this.$parent.lang
    this.getOrder()
    this.getCost()
  },
  data () {
    return {
      lang: 'ru',
      orderId: null,
      telInputValue: '',
      telInputStyleClasses: ['form__group'],
      telDropdownOptions: {
        showDialCodeInSelection: false,
        showFlags: false,
        showDialCodeInList: false,
        showSearchBox: false,
        disabled: true
      },
      telInputOptions: {
        placeholder: '+7-___-___-__-__',
        required: true,
        name: 'phone',
      },
      phone: '',
      receiver: '',
      street: '',
      building: '',
      room: '',
      entrance: '',
      floor: '',
      comment: '',
      payment: null,
      orderDate: null,
      orderTime: null,
      date: null,
      time: new Date().setHours(10, 0, 0, 0),
      soon: false,

      addresses: [],
      times: {
        start: '08:30',
        step: '00:30',
        end: '18:30',
      },
      disabledDate: false,
      disabledTime: false,
      cartCost: 0,
      deliveryCost: 0,
      totalCost: 0,

      invalidFields: [],

    }
  },
  methods: {
    telInputValidate (input) {
      this.disabled = !input.valid
      this.telInputStyleClasses = ['form__group']
      if (input.valid === true) {
        this.phone = input.number
        this.telInputStyleClasses.push('form__input--valid')
        this.sendField('phone', this.phone)
      }
    },
    telInputMask (value) {
      if (value === '87') {
        this.telInputValue = '+77'
      }
      if (value === '77') {
        this.telInputValue = '+77'
      }
    },
    async streetAutoComplete (event) {
      const value = event.target.value
      if (value.length > 2) {
        await this.addressFilter(value)
      } else {
        this.addresses = []
      }
    },
    async addressFilter (value) {
      const formdata = new FormData()
      formdata.append('street', value)
      formdata.append('building', this.building)
      const response = await request.get('order/address/search', formdata)
      if (response.data.success === true) {
        this.addresses = response.data.items
      }
    },
    setAddress (item) {
      this.street = item.street
      this.building = item.building
      this.deliveryCost = item.price
      this.addresses = []
      this.sendField('street', this.street)
      const vm = this

      let promise = new Promise((resolve) => {
        setTimeout(() => {
          vm.sendField('building', vm.building)
          resolve()
        }, 300)
      })
      promise.then(() => {
        this.getCost()
      })
    },
    setDefaultDate () {
      const now = new Date()
      const nowHours = now.getHours()
      const nowDay = now.getDate()
      if (nowHours > 19) {
        now.setDate(nowDay + 1)
      }
      return now
    },
    disableDates (date) {
      const now = new Date()
      const nowHours = now.getHours()
      const nowDay = now.getDate()
      if (nowHours > 19) {
        now.setDate(nowDay + 1)
      }

      now.setHours(0, 0, 0, 0)

      return date < now || date > new Date(now.getTime() + 5 * 24 * 3600 * 1000)
    },
    disableNotWorkingTime (date) {
      const setDate = new Date(this.date)
      const now = new Date()

      const setDay = setDate.getDate()
      const nowDay = now.getDate()

      if (setDay > nowDay) {
        return date.getHours() < 10 || date.getHours() > 20
      }
      if (setDay === nowDay) {
        const nowHours = now.getHours()
        if (nowHours > 19) {
          return date.getHours() < 10 || date.getHours() > 20
        }
        return date.getHours() <= nowHours || date.getHours() > 20
      }
    },
    renderTimes () {
      const setDate = new Date(this.date)
      const now = new Date()

      const setDay = setDate.getDate()
      const nowDay = now.getDate()

      if (setDay > nowDay) {
        this.renderNextDay()
      }
      if (setDay === nowDay) {
        this.renderToday()
      }
    },
    changeDate () {
      this.time = null
      this.renderTimes()

      this.sendField('delivery_date', this.date.toLocaleDateString())
    },
    renderToday () {
      this.times = {
        start: '10:00',
        step: '00:30',
        end: '20:00',
      }

      const setDate = new Date()

      const nowHours = setDate.getHours()
      const nowMinutes = setDate.getMinutes()

      if (this.time !== '' && this.time >= nowHours + ':' + nowMinutes) {
        return true;
      }

      if (nowHours < 20) {
        if (nowMinutes >= 30) {
          this.times.start = (nowHours + 2) + ':00'
        } else {
          this.times.start = (nowHours + 1) + ':30'
        }
        this.time = this.times.start
        this.changeTime()
      } else {
        this.disabledTime = true
      }
    },
    renderNextDay () {
      this.disabledTime = false
      this.times = {
        start: '10:00',
        step: '00:30',
        end: '20:00',
      }
      this.time = '10:00'
      this.changeTime()

    },
    setSoon () {
      this.soon = this.soon !== true
      if (this.soon === true) {
        this.time = null
        this.orderDate = null
        this.orderTime = null
      } else {
        this.date = this.setDefaultDate()
        this.sendField('delivery_date', this.date.toLocaleDateString())
        this.renderTimes()
      }
      this.disabledTime = this.soon
      this.disabledDate = this.soon
      this.sendField('soon', this.soon)
    },
    changeTime () {
      this.sendField('delivery_time', this.time)
    },
    async sendField (key, value) {
      const formData = new FormData()
      if (this.orderId !== null) {
        formData.append('id', this.orderId)
      }
      formData.append('key', key)
      formData.append('value', value)
      const response = await request.post('shop/order/add', formData)
      if (response.data.success === true) {
        const key = response.data.key
        this[key] = response.data.value
      }
    },
    async getOrder () {
      const formData = new FormData()
      if (this.orderId !== null) {
        formData.append('id', this.orderId)
      }
      formData.append('lang', this.lang)
      const response = await request.get('shop/order/get', formData)
      if (response.data.success === true) {
        const order = response.data.order
        this.receiver = order.receiver
        this.telInputValue = order.phone
        this.street = order.street
        this.building = order.building
        this.room = order.room
        this.entrance = order.entrance
        this.floor = order.floor
        this.comment = order.comment
        this.payment = order.payment
        this.soon = order.soon

        if (this.soon === true) {
          this.disabledDate = true
          this.disabledTime = true
        } else {
          if (order.delivery_date !== '') {
            this.date = new Date(order.delivery_date)
          }

          if (order.delivery_date === '') {
            this.date = this.setDefaultDate()
            this.sendField('delivery_date', this.date.toLocaleDateString())
          }

          if (order.delivery_time !== '') {
            this.time = order.delivery_time
          }
          this.renderTimes()
        }

        this.cartCost = order.cart_cost
        this.deliveryCost = order.delivery_cost
        this.totalCost = order.cost
      }
    },
    async getCost () {
      const formData = new FormData()
      if (this.orderId !== null) {
        formData.append('id', this.orderId)
      }
      const response = await request.get('shop/order/get-cost', formData)
      if (response.success === true) {
        this.cartCost = response.data.cost.cart_cost
        this.deliveryCost = response.data.cost.delivery_cost
        this.totalCost = response.data.cost.cost
      }
    },
    async submit () {
      this.invalidFields = []
      this.telInputStyleClasses = ['form__group']
      const formData = new FormData()
      if (this.orderId !== null) {
        formData.append('id', this.orderId)
      }
      const response = await request.post('shop/order/submit', formData)
      if (response.data.success === false) {
        if (response.data.message !== '') {
          window.miniShop2.Message.error(response.data.message)

          if (Array.isArray(response.data.data) && response.data.data.length > 0) {
            response.data.data.forEach(item => {
              if (item ===  'phone') {
                this.telInputStyleClasses = ['form__group', 'vue-tel-input--invalid']
              } else {
                this.invalidFields.push(item)
              }

            })
          }


        }
      }
      if (response.data.success === true) {
        if (response.data.redirect !== '') {
          window.location.href = response.data.redirect
        }
      }
    },
  },

}
</script>
