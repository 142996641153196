<template>
  <div class="modal__container">
    <button aria-label="Close modal" data-micromodal-close="" class="modal__close">
      <svg>
        <use xlink:href="/assets/template2/images/symbol/sprite.svg#remove"></use>
      </svg>
    </button>

    <header class="modal__header">
      <p id="modal-auth-title" class="modal__title"><span class="pretty">Вход</span> или регистрация </p>
    </header>
    <div id="modal-auth-content" class="modal__content">
      <form class="modal__form">
        <p class="form__label" style="margin-bottom: 9px;">По номеру телефона <span>*</span></p>
        <vue-tel-input
            :defaultCountry="'kz'"
            :mode="'international'"
            :validCharactersOnly="true"
            v-model="telInputValue"
            :dropdownOptions="telDropdownOptions"
            :inputOptions="telInputOptions"
            :styleClasses="telInputStyleClasses"
            @validate="telInputValidate"
            @input="telInputMask"
            :disabled="disabledPhone"
        />

        <div v-if="send">
          <div class="form__group" v-if="successSend">
            <label class="form__label" for="modal_auth_code">Код из SMS <span>*</span></label>
            <input type="number" class="form__input" id="modal_auth_code" placeholder="****"
              v-model="code"
            >
          </div>

          <button class="modal__changeNumber" type="button" @click="changePhone">Изменить номер телефона</button>

          <p class="modal__text" v-if="!userExists">Будет зарегистрирован пользователь с номером <strong>{{ telInputValue }}</strong> </p>
          <p class="modal__text">Отправить код еще раз через
            <timer
              @stopTimer="stopTimer()"
              :time="time"
          /> сек.</p>
        </div>
      </form>
    </div>
    <div class="modal__footer" v-if="!send">
      <button type="button" class="button modal__button"  :disabled="disabledSend" @click="sendSms"> Отправить SMS</button>
    </div>
  </div>
</template>

<script>
import { VueTelInput } from 'vue-tel-input'
import request from '@/request'
import Timer from '@/components/modalAuth/Timer.vue'

export default {
  name: 'ModalAuth',
  components: {
    VueTelInput,
    Timer
  },
  created () {
    this.lang = this.$parent.lang
  },
  watch: {
    code(value) {
      let code = value.replace(/[^0-9]/g, '')
      code = code.substr(0, 4)
      if (String(code).length <= 4) {
        this.code = code
      }
      if (code.length === 4) {
        this.sendCode(code)
      }
    },
  },
  data () {
    return {
      lang: 'ru',
      telInputValue: '',
      telInputStyleClasses: ['form__group'],
      telDropdownOptions: {
        showDialCodeInSelection: false,
        showFlags: false,
        showDialCodeInList: false,
        showSearchBox: false,
        disabled: true
      },
      telInputOptions: {
        placeholder: '+7-___-___-__-__',
        required: true,
        name: 'phone',
      },
      phone: '',
      send: false,
      successSend: false,
      disabledSend: true,
      disabledPhone: false,
      userExists: true,
      time: 0,
      timerIsActive: false,
      code: ''
    }
  },
  methods: {
    telInputValidate (input) {
      this.disabled = !input.valid
      this.telInputStyleClasses = ['form__group']
      if (input.valid === true) {
        this.phone = input.number
        this.telInputStyleClasses.push('form__input--valid')
        this.disabledSend = false
      }
    },
    telInputMask (value) {
      if (value === '87') {
        this.telInputValue = '+77'
      }
      if (value === '77') {
        this.telInputValue = '+77'
      }
    },
    async sendSms() {
      this.disabledSend = true
      this.disabledPhone = true
      const formData = new FormData()
      formData.append('phone', this.phone)
      const response = await request.post('shop/auth/send-sms', formData)
      if (response.data.success === true) {
        this.send = true
        this.successSend = true
        this.userExists = response.data.user_exists
        if (response.data.time !== undefined) {
          this.time = response.data.time
        }
        if (response.data.message !== undefined) {
          window.miniShop2.Message.success(response.data.message)
        }
      }

      if (response.data.success === false) {
        this.send = true
        if (response.data.message !== undefined) {
          window.miniShop2.Message.error(response.data.message)
        }

        if (response.data.time !== undefined) {
          this.time = response.data.time
        }
      }
    },
    changePhone() {
      this.disabledSend = true
      this.disabledPhone = false
      this.telInputValue = ''
      this.phone = ''
      this.userExists = true
      this.send = false
      this.successSend = false
    },
    stopTimer() {
      this.timerIsActive = false
      this.disabledSend = false
      this.send = false
      this.successSend = false
    },
    async sendCode() {
      const formData = new FormData()
      formData.append('phone', this.phone)
      formData.append('code', this.code)
      const response = await request.post('shop/auth/check-code', formData)
      if (response.data.success === true) {
        if (response.data.message !== undefined) {
          window.miniShop2.Message.success(response.data.message)
        }
        if (response.data.reload === true) {
          window.location.reload()
        }
      }
      if (response.data.success === false) {
        if (response.data.message !== undefined) {
          window.miniShop2.Message.error(response.data.message)
        }
      }
    },
  }
}
</script>