<template>
  <div class="cartPage__cart fullCart">
    <div class="fullCart__header" v-if="lang==='ru'">
      <span class="fullCart__headerTitle">В вашей корзине </span>
      <span class="fullCart__count">{{ total.count }}</span>
    </div>

    <div class="fullCart__header" v-if="lang==='kz'">
      <span class="fullCart__headerTitle">Сіздің қоржыныңызда </span>
      <span class="fullCart__count">{{ total.count }}</span>
    </div>


    <div class="fullCart__body">
      <div class="fullCart__row"
           v-for="row in cart"
           :key="row.key"
      >
        <div class="fullCart__left">
          <a :href="row.product.url" class="fullCart__imageLink">
            <img :src="row.product.thumb" :alt="row.product.name">
          </a>
          <div class="fullCart__title">
            <a :href="row.product.url" class="fullCart__titleLink">
              {{ row.product.name }}
            </a>

            <span class="fullCart__option">{{ row.product.size }} {{ row.product.unit }}</span>
          </div>
        </div>
        <div class="fullCart__right">
          <div class="fullCart__rightTop">
            <div class="fullCart__detail fullCart__price">
              <span class="fullCart__label" v-if="lang==='ru'">Цена</span>
              <span class="fullCart__label" v-if="lang==='kz'">Бағасы</span>
              <span class="fullCart__detailValue">{{ row.product.price }} тнг</span>
            </div>

            <div class="fullCart__detail fullCart__rowCount">
              <span class="fullCart__label" v-if="lang==='ru'">Количество</span>
              <span class="fullCart__label" v-if="lang==='kz'">Саны</span>
              <div class="fullCart__detailValue cartCounts">
                <button class="cartCounts__button"
                        @click="change(row.key, row.count - 1)"
                >-
                </button>
                <input type="number" min="1" max="1000" step="1" :value="row.count" class="cartCounts__text"
                  @change="change(row.key, $event.target.value)"
                >
                <button class="cartCounts__button"
                        @click="change(row.key, row.count + 1)"
                >+
                </button>
              </div>
            </div>

            <div class="fullCart__detail fullCart__cost">
              <span class="fullCart__label" v-if="lang==='ru'">Стоимость</span>
              <span class="fullCart__label" v-if="lang==='kz'">Барлығы</span>
              <span class="fullCart__detailValue">{{ row.product.cost }} тнг</span>
            </div>
          </div>

          <p class="fullCart__warning"  v-if="lang==='ru'">Точная сумма будет озвучена оператором после взвешивания вашего заказа</p>
          <p class="fullCart__warning"  v-if="lang==='kz'">Нақты соманы оператор сіздің тапсырысыңызды өлшегеннен кейін хабарлайды</p>
        </div>


        <button class="fullCart__remove"  @click="remove(row.key)">
          &times;
        </button>
      </div>

    </div>

    <div class="fullCart__footer">
      <div class="fullCart__footerLeft" v-if="lang==='ru'">
        Точная сумма будет озвучена оператором после взвешивания вашего заказа
      </div>
      <div class="fullCart__footerLeft" v-if="lang==='kz'">
        Нақты соманы оператор сіздің тапсырысыңызды өлшегеннен кейін хабарлайды
      </div>
      <div class="fullCart__footerRight">
        <div class="fullCart__footerRightTop">
          <span class="fullCart__footerTotal" v-if="lang==='ru'">Итого:</span>
          <span class="fullCart__footerTotal" v-if="lang==='kz'">Барлығы:</span>
          <span class="fullCart__footerCost">{{ total.cart_cost }} тнг </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from '@/request'

export default {
  name: 'CartApp',
  created () {
    this.orderId = this.$parent.orderId
    this.lang = this.$parent.lang
    this.get()
  },
  data () {
    return {
      lang: 'ru',
      orderId: 0,
      cart: {},
      total: {},
    }
  },
  methods: {
    async get () {
      const formData = new FormData()
      if (this.orderId !== null) {
        formData.append('id', this.orderId)
      }
      formData.append('lang', this.lang)
      const response = await request.get('shop/cart/get', formData)
      if (response.data.success === true) {
        this.cart = response.data.cart
        this.total = response.data.total

        const $cartCost = document.querySelector('.order__cartCost')
        const $deliveryCost = document.querySelector('.order__deliveryCost')
        const $cost = document.querySelector('.order__cost')

        $cartCost.textContent = `${this.total.cart_cost} тнг`
        $deliveryCost.textContent = `${this.total.delivery_cost} тнг`
        $cost.textContent = `${this.total.total_cost} тнг`
      }
    },
    async change (key, count) {
      const formData = new FormData()
      if (this.orderId !== null) {
        formData.append('id', this.orderId)
      }
      formData.append('key', key)
      formData.append('count', count)
      const response = await request.post('shop/cart/change', formData)
      if (response.data.success === true) {
        if (response.data.message !== '') {
          window.miniShop2.Message.success(response.data.message)
        }
        await this.get()
      }
      if (response.data.success === false) {
        if (response.data.message !== '') {
          window.miniShop2.Message.error(response.data.message)
        }
      }
    },
    async remove(key) {
      const formData = new FormData()
      if (this.orderId !== null) {
        formData.append('id', this.orderId)
      }
      formData.append('key', key)
      const response = await request.post('shop/cart/remove', formData)
      if (response.data.success === true) {
        if (response.data.message !== '') {
          window.miniShop2.Message.success(response.data.message)
        }
        if (Object.keys(response.data.data.cart).length > 0) {
          await this.get()
        } else {
          window.location.reload();
        }
      }
      if (response.data.success === false) {
        if (response.data.message !== '') {
          window.miniShop2.Message.error(response.data.message)
        }
      }
    },
  },
}
</script>